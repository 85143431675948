.container {
  margin-bottom: 1em;
  max-width: 88vw;
}

.toggle {
  outline: none;
  border: none;

  width: 100%;
  height: 3.8rem;

  text-align: left;

  font-size: 2.25rem;

  text-transform: uppercase;

  display: flex;
  align-items: center;

  padding: 0;
  margin: 0;
}

.toggle span {
  height: inherit;
  line-height: 2em;
}

.caret {
  height: inherit;
  margin: 0 .5em 0 1em;
}

.closed {
  composes: toggle;
  background: var(--bg);
  color: var(--fg);
  outline: 1px solid var(--fg);
}

.closed .caret {
  fill: var(--fg);
}

.closed:hover {
  background: var(--fg);
  color: white;
}

.closed:hover .caret {
  fill: white;
}

.open {
  composes: toggle;
  background: var(--fg);
  color: white;
}

.open .caret {
  transform: rotate(90deg);
  fill: white;
}

.content {
  font-size: 1rem;
  font-family: var(--body-font);

  color: var(--one);
  background: white;

  margin: 0;
  padding: 0 3em;

  overflow-x: hidden;
  overflow-y: auto;

  height: auto;
}

.content *:first-child {
  margin-top: 1em;
}

/* .content p { */
/*   padding-left: 1em; */
/* } */

