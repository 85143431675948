/* Chat */
.chat_box {
  position: absolute;
  width: 26em;

  background: white;
  border-radius: 1.75em;

  top: 4rem;
  left: calc(50% - 13em);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 100;

  padding: 1.625em 0 1em 0;

  box-shadow: 3px 5px 5px -3px rgba(0, 0, 0, 0.45);
}

.phones {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1em;
}

.phones h3 {
  display: inline-block;
  font-size: 2.5em;

  padding: 0;
  margin: 0;

  color: var(--two);
}

.phones h3 a {
  color: inherit;
  text-decoration: none;
}

.phones h3 a:hover {
  text-decoration: underline;
}

.phone {
  margin: 0 1rem;
  height: 1.25rem;
  width: 1.25rem;
  padding-bottom: 0.5rem;
}

.chat_btn {
  border: none;
  outline: none;

  width: 24rem;
  height: 3.5rem;

  background: var(--one);
  border-radius: 3.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 2rem 0 1.5rem;
  margin: 0;

  color: white;

  font-size: 2em;
  font-family: var(--body-font);
  font-weight: 300;
  letter-spacing: -1px;

  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.bubble {
  padding: 0;
  margin: 0;
  height: 2.5rem;
  width: 2.5rem;

  outline: none;
  background: none;
  border: none;

  fill: white;
}

.chat_btn:hover {
  cursor: pointer;
  transform: scale(1.02);
  background: var(--two);
}

/* Media queries */
@media (max-width: 810px) { /* < 810px */
  .chat_box {
    top: calc(50% - 6.5rem);
    left: calc(50% - 13rem);
  }
}

