/* Marquee */
.marquee_wrapper {
  min-height: max(100vh, 100vw / var(--ratio));
  height: 100vh;
  width: 100%;

  position: absolute;
  top: 0;

  pointer-events: none;
  z-index: 100;
}

.marquee {
  background: white;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 3em;
}

.marquee .track {
  height: 100%;
  display: flex;
  align-items: center;
}

.marquee .pair {
  flex: 0 0 auto;
}

.marquee .track .word {
  font-size: 1.5em;
  font-weight: 300;
  padding: 0 .25em;
  text-transform: uppercase;
  color: var(--two);
}

.marquee svg {
  display: inline;
  height: 1.25em;
  width: auto;
  padding: 0 .5em;
}

