@font-face {
  font-family: 'Thunder';
  src: url('./fonts/thunder/Thunder-LightLC.woff') format('woff'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-LightLC.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-LightLC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
} @font-face {
  font-family: 'Thunder';
  src: url('./fonts/thunder/Thunder-LC.woff') format('woff'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-LC.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-LC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
} @font-face {
  font-family: 'Thunder';
  src: url('./fonts/thunder/Thunder-SemiBoldLC.woff') format('woff'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-SemiBoldLC.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-SemiBoldLC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
} @font-face {
  font-family: 'Thunder';
  src: url('./fonts/thunder/Thunder-BoldLC.woff') format('woff'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-BoldLC.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-BoldLC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
} @font-face {
  font-family: 'Thunder';
  src: url('./fonts/thunder/Thunder-BlackLC.woff') format('woff'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-BlackLC.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-BlackLC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 800;
  text-rendering: optimizeLegibility;
} @font-face {
  font-family: 'Thunder';
  src: url('./fonts/thunder/Thunder-ExtraBoldLC.woff') format('woff'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-ExtraBoldLC.woff2') format('woff2'), /* Modern Browsers */
       url('./fonts/thunder/Thunder-ExtraBoldLC.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

:root {
  --one: #0B3954;
  --two: #FF6978;
  --one-lighter: #104361;
  --two-lighter: #FF7A88;
  --ratio: 2.5;
  --primary-font: 'Thunder', 'Inter', 'Helvetica Neue';
  --body-font: Inter, Helvetica, 'Helvetica Neue', Arial, sans-serif;
}

html {
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;

  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

  position: absolute;
  min-height: max(100vh, 100vw / var(--ratio));
  height: 100%;
  width: 100vw;

  overflow-x:  hidden;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

p {
  font-family: var(--body-font);
}

/* Media queries */
@media (max-width: 1280px) { /* < 1280px */
  html, body {
    font-size: 13px;
  }
}

@media (max-width: 810px) { /* < 810px */
  :root {
    --ratio: 2.0;
  }
}

@media (max-width: 500px) { /* < 500px */
  html, body {
    font-size: 11px;
  }
}

@media (max-height: 660px) { /* height < 660px */
  html, body {
    font-size: 8px;
  }
}

