.container {
  position: absolute;
  right: 0;
  top: 0;

  padding: 1em 1em 0 0;

  z-index: 199;
}

.button {
  height: 2em;
  width: 2em;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: none;
  outline: none;
  border: none;

  padding: 0;
  margin: 0 .25em;

  fill: var(--one);
}

.button:hover {
  background: var(--two);
  cursor: pointer;
  fill: white;
}

.button svg {
  height: 1.625em;
  width: 1.625em;
  flex: 0 0 auto;
}

.info {
  composes: button;
}

.lang {
  composes: button;
}

.dropdown {
  background: var(--two);
  border-radius: 0 0 3em 3em;

  overflow: hidden;
  height: 0;

  position: absolute;
  right: 0;
  top: 3rem;

  padding: .25em 0 .25em 0;
  margin: 0;

  list-style: none;

  width: 4.5rem;

  font-size: 1.75em;
  text-transform: uppercase;
  font-weight: 500;
  color: var(--one);

  transform: scale(1, 0.1);
  transform-origin: 0 0;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  opacity: 0;
}

.lang:hover .dropdown {
  transform: scale(1, 1);
  height: 2.5em;
  opacity: 1;
}

.open {
  composes: dropdown;
  transform: scale(1, 1);
  height: 2.5em;
  opacity: 1;
}

.link {
  color: inherit;
  text-decoration: none;
}

.link:hover {
  color: white;
}

/* Media queries */
@media (max-width: 810px) { /* < 810px */
  .container {
    width: 6em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em 1em 1em 0;
    z-index: 199;

    background: var(--two);
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.6);
    border-radius: 0 0 0 1em;
  }
}

