.blackout {
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  z-index: 201;
}

.container {
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  background: var(--bg);

  z-index: 202;

  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.close {
  position: absolute;
  right: 0;
  top: 0;

  padding: .5em .5em 0 0;
  margin: 0;

  background: none !important;
  outline: none !important;
  border: none !important;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--fg);
}

.close:hover * {
  color: white;
  stroke: white !important;
  cursor: pointer;
}

.close .text {
  font-size: 2.5em;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: .125em;
}

.close svg {
  width: 2.5em;
  height: 3em;
  stroke: var(--fg);
  stroke-width: .3em;
  padding-left: .5em;
}

.title {
  display: block;
  font-size: 9.3vw;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1em;

  padding: 0;
  margin: .5em 0 0 0;
  
  z-index: 100;

  color: var(--fg);
  
  text-align: center;
}

.title .question_mark {
  display: inline-block;
  padding: 0 0 0 0.1em;
  margin: 0;

  color: transparent;
  -webkit-text-stroke: 2px var(--fg);
}

.content {
  max-width: 61vw;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  gap: 2em;
}

.information {
  flex: 1 0 calc(45% - 1em);

  font-size: 1.125em;
  color: var(--fg);
}

.information p {
  margin: 0 0 2rem 0;
}

.information p a {
  color: inherit;
  text-decoration: none;
}

.information p a:hover {
  text-decoration: underline;
  color: white;
}

.information p:first-child {
  font-weight: bold;
  font-size: 1.5em;
}

.resources {
  flex: 1 0 calc(55% - 1em);
  min-width: 80ch;
}


/* Media queries */
@media (max-width: 500px) { /* < 500px */
  .title {
    margin-top: 2em;
  }

  .content {
    max-width: none;
    justify-content: stretch;
  }

  .information {
    flex: 1 1 auto;
    padding: 2em 2em 0 2em;
  }

  .resources {
    flex: 1 1 auto;
    padding: 0 2em;
  }
}

