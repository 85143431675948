.blackout {
  height: 100vh;
  width: 100vw;

  position: absolute;
  background: black;

  z-index: 99999;
}

.landing {
  height: calc(100vh - 3em);
  min-height: calc(max(100vh, 100vw / var(--ratio)) - 3em);

  margin: 0 auto;

  display: flex;

  overflow: hidden;
}

/* Sections */
.section {
  flex: 1 1 50%;
  min-height: 100%;

  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;

  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.section .hero {
  font-size: min(8em, 7vw);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1em;

  padding: 0;
  margin: 0;
  
  z-index: 100;

  text-align: center;

  transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.section .title {
  font-size: 1em;
  display: inline;
}

.section .hero .question_mark {
  color: transparent;
}

.section .button {
  display: block;
  width: 100%;
  font-size: 2rem;
  height: 2.625rem;
  border-radius: 1.3125rem;

  font-weight: 500;
  letter-spacing: 0.3em;

  background: none;
  color: inherit;
  border: 2px solid black;

  text-transform: none;
  text-align: center;

  text-decoration: none;
  padding: 0;
  margin: 0;

  cursor: pointer;
}

.look {
  composes: section;
  background: var(--one);
  outline-color: var(--one);
}

.look .hero {
  color: var(--two);
}

.look .button {
  border-color: var(--two);
  background: var(--one);
}

.look .hero .question_mark {
  -webkit-text-stroke: 2px var(--two);
}

.do {
  composes: section;
  background: var(--two);
  outline-color: var(--two);
}

.do .hero {
  color: var(--one);
}

.do .button {
  border-color: var(--one);
  background: var(--two);
}

.do .hero .question_mark {
  -webkit-text-stroke: 2px var(--one);
}

/* Section Hovers */
.section:hover {
  cursor: pointer;
}

.section:hover .hero {
  transform: scale(1.05) !important;
}

.look:hover {
  background: var(--one-lighter);
}

.look:hover .button {
  background: var(--two);
  color: var(--one);
  border-color: var(--two);
}

.do:hover {
  background: var(--two-lighter);
}

.do:hover .button {
  background: var(--one);
  color: var(--two);
  border-color: var(--one);
}

/* Media queries */
@media (max-width: 810px) { /* < 810px */
  .landing {
    flex-direction: column-reverse;
  }

  .section {
    flex: 1 1 50%;
    min-height: 1px;

  }

  .chat_box {
    height: 6em;
    width: 30em;
    top: calc(calc(50vh - 1.5em) - 3em);
    left: calc(50% - 15em);
  }

  .chat_form {
    width: 25em;
  }

  .section .hero {
    font-size: 7em;
  }
}

@media (max-width: 375px) { /* < 375px */
  .section .hero {
    font-size: 6em;
  }
}

