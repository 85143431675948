.box {
  position: absolute;
  right: 0;
  top: 4em;

  background: white;
  min-height: calc(max(100vh, 100vw / var(--ratio)) - 7em);
  max-width: 100vw;
  width: 32em;

  color: var(--one);

  display: flex;
  flex-direction: column;

  box-shadow: -5px -3px 5px -3px rgba(0, 0, 0, 0.45);
  border-radius: 1.68em 0 0 0;
}

.marker {
  position: absolute;
  width: 1em;
  height: 1em;
  background: white;
  right: 4.25em;
  top: -0.5em;

  transform: rotate(45deg);
}

.box h2:first-of-type {
  font-size: 3em;
  padding: 0 2rem;
  margin: 2rem 0;
}

.box p {
  font-family: 'Thunder';
  padding: 0 2rem;
  font-size: 1.2em;
}

.box p:first-of-type {
  font-size: 1.5em;
  font-weight: 500;
}

.logos {
  height: auto;
  border-top: 1px solid var(--one);

  width: calc(100% - 4em);
  margin: auto auto 2em auto;
}

.logos img {
  flex: 0 1 33% !important;
  width: 33%;
}

.logos .topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.logos .botRow {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
  gap: 1em;
}

