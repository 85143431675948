.wrapper {
  position: absolute;
  min-height: max(100vh, 100vw / var(--ratio));
  height: 100vh;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  overflow: hidden;
}

.sticker {
  position: absolute;
  pointer-events: none;

  z-index: 10;
}

.star_wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-content: space-between;
  align-items: center;

  flex-wrap: wrap;
}

.stars {
  width: 45%;
  height: 80%;
  pointer-events: none;
}

.okeii {
  composes: sticker;
  width: 19vw;
  left: -1%;
  top: -6%;
  transform: rotate(-16deg);
}
.balloon {
  composes: sticker;
  width: 12vw;
  left: -1%;
  top: 28%;
  transform: rotate(-10deg);
}
.fish {
  composes: sticker;
  width: 11vw;
  left: 19%;
  top: 8%;
  transform: rotate(32deg);
}
.italian {
  display: none;
  composes: sticker;
  width: 8.6vw;
  right: 25%;
  top: 14%;
  transform: rotate(-67deg);
}
.mirror {
  composes: sticker;
  width: 15vw;
  left: -4%;
  bottom: 23%;
  transform: rotate(-7deg);
}
.ok {
  composes: sticker;
  width: 11vw;
  left: 40%;
  top: 42%;
  transform: rotate(-24deg);
}
.frown {
  composes: sticker;
  width: 11vw;
  right: 1%;
  top: 36%;
  transform: rotate(20deg);
}
.hand {
  composes: sticker;
  width: 20vw;
  left: 1%;
  bottom: -1%;
  transform: rotate(-16deg);
}
.ice {
  composes: sticker;
  width: 9vw;
  left: 45%;
  bottom: 0;
  transform: rotate(-6deg);
}
.melt {
  composes: sticker;
  width: 12vw;
  left: 30%;
  top: -3%;
  transform: rotate(-15deg);
}
.puppet {
  composes: sticker;
  width: 10vw;
  right: 2%;
  bottom: 8%;
  transform: rotate(-20deg);
}
.hotdog {
  composes: sticker;
  width: 13vw;
  right: 22%;
  bottom: 1%;
}
.seam {
  composes: sticker;
  width: 11vw;
  right: 32%;
  top: 1%;
}
.slay {
  composes: sticker;
  width: 11vw;
  right: -3%;
  top: 12%;
  transform: rotate(-18deg);
}
.smiley {
  composes: sticker;
  width: 8vw;
  right: 14%;
  top: -2%;
  transform: rotate(-16deg);
}
.typo {
  composes: sticker;
  width: 11vw;
  left: 30%;
  bottom: 18%;
  transform: rotate(-10deg);
}
.uuuok {
  composes: sticker;
  width: 10vw;
  right: 24%;
  top: 14%;
}
.water {
  composes: sticker;
  width: 7vw;
  right: 38%;
  bottom: 24%;
  transform: rotate(10deg);
}

/* Media queries */
@media (max-width: 810px) { /* < 810px */
  .okeii {
    width: 21vw;
    left: -4%;
    top: 6%;
  }
  .balloon {
    width: 16vw;
    left: 4%;
    top: 27%;
  }
  .fish {
    width: 16vw;
    left: 14%;
    top: 16%;
  }
  .italian {
    width: 11vw;
    left: 25%;
    top: -2%;
  }
  .mirror {
    width: 19vw;
    left: 1%;
    top: 46%;
  }
  .ok {
    width: 16vw;
    left: 15%;
    top: 36%;
  }
  .typo {
    width: 16vw;
    left: 9%;
    bottom: 28%;
  }
  .hand {
    width: 24vw;
    left: -6%;
    bottom: 10%;
  }
  .water {
    width: 10vw;
    left: 18%;
    bottom: 2%;
  }
  .ice {
    width: 12vw;
    left: auto;
    right: 16%;
    bottom: 2%;
  }
  .puppet {
    width: 16vw;
    right: -4%;
    bottom: 12%;
  }
  .hotdog {
    width: 22vw;
    right: 6%;
    bottom: 32%;
  }
  .melt {
    width: 18vw;
    left: auto;
    right: 2%;
    top: 46%;
  }
  .frown {
    width: 16vw;
    left: auto;
    right: 1%;
    top: 29%;
  }
  .uuuok {
    width: 16vw;
    left: auto;
    right: 16%;
    top: 36%;
  }
  .seam {
    width: 16vw;
    left: auto;
    right: 12%;
    top: 18%;
  }
  .smiley {
    width: 11vw;
    left: auto;
    right: 24%;
    top: 0.5%;
  }
  .slay {
    width: 18vw;
    left: auto;
    right: 0;
    top: 0;
  }
}

@media (max-width: 500px) { /* < 500px */
  .star_wrapper {
    width: 100vw;
    height: 100vh;
  }
  .stars {
    width: 80%;
    height: 40%;
  }
  .okeii {
    width: 27vw;
    left: -5%;
    top: 38%;
  }
  .balloon {
    width: 25vw;
    left: -4%;
    top: auto;
    bottom: 20%;
  }
  .fish {
    width: 30vw;
    left: 1%;
    top: 3%;
    transform: rotate(-20deg);
  }
  .italian {
    width: 18vw;
    left: -5%;
    top: 12%;
  }
  .mirror {
    display: none;
  }
  .ok {
    display: none;
  }
  .typo {
    display: none;
  }
  .hand {
    width: 38vw;
    left: -9%;
    bottom: -2%;
    transform: rotate(0deg);
  }
  .water {
    display: none;
  }
  .ice {
    width: 32vw;
    right: -16%;
    top: 18%;
    bottom: auto;
  }
  .puppet {
    width: 20vw;
    right: -3%;
    bottom: 26%;
  }
  .hotdog {
    width: 34vw;
    right: auto;
    left: -1%;
    bottom: 34%;
  }
  .melt {
    display: none;
  }
  .frown {
    display: none;
  }
  .uuuok {
    width: 30vw;
    right: 22%;
    top: auto;
    bottom: -1%;
  }
  .seam {
    display: none;
  }
  .smiley {
    width: 18vw;
    left: 1%;
    top: 16%;
  }
  .slay {
    width: 40vw;
    right: -2%;
    top: 4%;
    transform: rotate(20deg);
  }
}
